import { cloneDeep, sortBy, uniqBy } from 'lodash';
import getDate from '../../utils/getDate';
import isDateInRange from '../../utils/isDateInRange';
import {
  BAR_COLOR_WAY,
  FDA_FILTERS,
  EMA_FILTERS,
  EU_TRIALS_FILTERS,
  GRAPH_ENTITIES,
  GRAPH_ENTITY_DATA_MAPPING,
  horizontalBarDefaultConfig,
  horizontalBlackShapeDefaultConfig,
  horizontalReviewBarDefaultConfig,
  OPP_BAR_COLOR_WAY,
  selectedBarMarker,
  SUBMISSION_CLASS_COLOR,
  todayAnnotationsDefaultConfig,
  todayVerticalLineDefaultConfig,
  TRIALS_FILTERS,
  verticalGreenBarDefaultConfig
} from './const';
import { processRegulatoryInfoMeetings } from './regInfoUtils';

const getTimestampDiff = (startDateString: string, endDateString: string) => {
  return new Date(startDateString).getTime() - new Date(endDateString).getTime();
};

export const getSelectedBarMarker = () => cloneDeep(selectedBarMarker);

export const getDefaultQuery = (
  activeIngredient: string,
  includeControllable = false,
  applicationSource = 'us'
) => {
  /* 
    Returns the default querues. If we want only locked queries we can use  includeControllable=false.
    If we need eveything, we can use includeControllable = true.

    Example case: 
    In the case of agency_class, first load we need to use 'INDUSTRY' as the value. 
    If user clears filter or removes 'INDUSTRY' using filter-ui, we should not consider it as default.
  */
  const queries = [
    {
      category: 'active_ingredients',
      searchText: activeIngredient,
      controllable: false // Controllables are nothing but if user can modify it using UI. active_ingredients is always locked to the given app_num.
    },
    {
      category: 'agency_class',
      searchText: applicationSource === 'us' ? 'INDUSTRY' : 'commercial',
      controllable: true // Controllables are nothing but if user can modify it using UI. agency_class can be updated using filters.
    }
  ];
  return queries.filter(qry => {
    if (includeControllable) {
      return true;
    }
    return !qry.controllable;
  });
};

export const prepareGraphData = (
  apiData: any,
  nctIdsInReview: Array<string> = [],
  trialsReview: Array<any> = []
) => {
  const ctIds: Array<string> = [];
  const barGraphData: Array<any> = [];

  const nctIdTrialsInReviewMap: any = {};

  trialsReview.forEach(
    ({ id: ctId, fitted_start_date: startDate, submissionstatusdate: endDate }: any) => {
      nctIdTrialsInReviewMap[ctId] = {
        nctId: ctId,
        // if nct id has multiple black lines, pick the earliest starting date.
        startDate: nctIdTrialsInReviewMap[ctId]?.startDate
          ? Math.min(
              new Date(nctIdTrialsInReviewMap[ctId].startDate).getTime(),
              new Date(startDate).getTime()
            )
          : startDate,
        // if nct id has multiple black lines, pick the earliest end date.
        endDate: nctIdTrialsInReviewMap[ctId]?.endDate
          ? Math.min(
              new Date(nctIdTrialsInReviewMap[ctId].endDate).getTime(),
              new Date(endDate).getTime()
            )
          : endDate
      };
    }
  );

  // eslint-disable-next-line no-param-reassign
  apiData.trials_data = apiData.trials_data?.sort(
    (a: any, b: any) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
  );
  apiData.trials_data.forEach(
    ({
      nct_id: nctId,
      euct_id: euctId,
      start_date: startDate,
      primary_completion_date: primaryCompletionDate,
      completion_date: endDate,
      enrollment: sampleSize,
      phase,
      study_title: studyTitle,
      overall_status: status,
      trial_information: trialInformation
    }: any) => {
      const ctId = nctId || euctId;
      // NCT IDs to render in the Y - Axis
      ctIds.push(ctId);

      const nctIdPresentInReviewData: boolean = !!nctIdsInReview?.includes(ctId);

      // Horizontal Bar Graph data
      if (startDate && endDate) {
        // If the NCT ID Present in Review, it should be highlighted with green
        const color = nctIdPresentInReviewData
          ? '#83D0C4'
          : BAR_COLOR_WAY[Math.floor(barGraphData.length / 2) % BAR_COLOR_WAY.length];
        barGraphData.push({
          ...horizontalBarDefaultConfig,
          name: ctId,
          offsetgroup: ctId,
          legendgroup: ctId,
          hoverinfo: 'x+y+text',
          base: [startDate],
          x: [getTimestampDiff(endDate, startDate)],
          y: [ctId],
          text_auto: '.2s',
          marker: {
            color
          },
          customdata: [
            [
              GRAPH_ENTITIES.CLINICAL_TRIAL,
              sampleSize,
              phase,
              studyTitle,
              startDate,
              endDate,
              nctIdPresentInReviewData,
              primaryCompletionDate,
              status
            ]
          ]
        });

        // Primary completion bar for each trial bar
        // eslint-disable-next-line no-unused-expressions
        if (primaryCompletionDate)
          barGraphData.push({
            ...horizontalBarDefaultConfig,
            name: ctId,
            // offsetgroup: nctId,
            legendgroup: ctId,
            hoverinfo: 'none',
            base: [primaryCompletionDate],
            x: [getTimestampDiff(endDate, primaryCompletionDate)],
            y: [ctId],
            ...{
              marker: {
                color: OPP_BAR_COLOR_WAY[color],
                opacity: 0.8
              }
            },
            customdata: [
              [
                GRAPH_ENTITIES.CLINICAL_TRIAL,
                sampleSize,
                phase,
                studyTitle,
                startDate,
                endDate,
                nctIdPresentInReviewData,
                primaryCompletionDate,
                status
              ]
            ]
          });

        // To show the text out side the bar lines. We could have done with text annotations, but annotation does not scale down text size when we zoom out.
        // So we are going with text inside a transparant bar graph.
        const labelStart = new Date(
          Math.min(
            new Date(startDate).getTime(),
            new Date(nctIdTrialsInReviewMap[ctId]?.startDate || startDate).getTime(),
            new Date(nctIdTrialsInReviewMap[ctId]?.endDate || startDate).getTime()
          )
        )
          .toISOString()
          .split('T')[0];

        barGraphData.push({
          type: 'bar',
          name: ctId,
          customType: 'fake_label',
          x: [labelStart],
          y: [ctId],
          orientation: 'h',
          text: `N: ${sampleSize} | ${phase}`,
          textfont: { color: nctIdPresentInReviewData ? '#0A806DFF' : '#7D7D7D', size: 14 },
          xref: 'x',
          yref: 'y',
          axhref: 'x',
          ayhref: 'y',
          textposition: 'inside',
          insidetextanchor: 'end',
          marker: {
            color: '#00000000'
          },
          hoverinfo: 'none',
          trialInformation,
          customdata: [
            [
              ctId,
              sampleSize,
              phase,
              studyTitle,
              startDate?.split('T')[0],
              endDate?.split('T')[0],
              nctIdPresentInReviewData,
              primaryCompletionDate?.split('T')[0],
              status
            ]
          ]
        });
      }
    }
  );

  return {
    title: apiData.title,
    ctIds,
    barGraphData
  };
};

export const prepareOverlayData = (apiData: any, meetingData?: any, source = 'us') => {
  const shapes: Array<any> = [];
  const annotations: Array<any> = [];
  let trialsInReview: Array<any> = [];
  let trialReviewBars: Array<any> = [];
  const nctIdsInReview: Array<string> = [];
  const { overlay_data: overlays } = apiData;
  const negativeAxisNCTIds: Array<string> = [];

  const trialsReviewBarDates: any = {};
  const submissionFlagColor: any = {};
  let trialsReview: Array<any> = [];
  overlays[source].submission_flags = overlays[source].submission_flags?.sort(
    (a: any, b: any) => new Date(a.end_date).getTime() - new Date(b.end_date).getTime()
  );

  overlays[source].submission_flags.forEach(
    ({
      end_date: endDate,
      start_date: startDate,
      display_date: displayStartDate,
      title,
      submission,
      submission_class_type: submissionClassType,
      action_details: actionDetails,
      meetings
    }: any) => {
      const color = SUBMISSION_CLASS_COLOR[submissionClassType] || SUBMISSION_CLASS_COLOR.others;
      submissionFlagColor[endDate] = { color, title, actionDetails, submission };
      shapes.push({
        ...verticalGreenBarDefaultConfig,
        line: {
          ...verticalGreenBarDefaultConfig.line,
          color
        },
        x0: endDate,
        x1: endDate,
        submission,
        endDate,
        source: 'submission'
      });
      shapes.push({
        ...verticalGreenBarDefaultConfig,
        line: {
          ...verticalGreenBarDefaultConfig.line,
          color
        },
        x0: endDate,
        x1: endDate,
        submission,
        yref: 'y2 domain',
        y0: 0.2
      });

      trialReviewBars.push({
        ...horizontalReviewBarDefaultConfig,
        marker: {
          ...horizontalReviewBarDefaultConfig.marker,
          color
        },
        name: `${source ==='us'?submission:actionDetails}`,
        offsetgroup:  `${source ==='us'?submission:actionDetails}`,
        legendgroup:  `${source ==='us'?submission:actionDetails}`,
        base: [startDate],
        endDate, // For our reference. Not used in the graph rendering.
        startDate,
        x: [getTimestampDiff(endDate, startDate)],
        y: [trialReviewBars.length % 10],
        hovertemplate: `${source ==='us'?submission:actionDetails}`,
        customdata: [
          [
            GRAPH_ENTITIES.FDA_APPROVAL,
            submission,
            actionDetails,
            title,
            endDate,
            displayStartDate,
            meetings,
            meetings
          ]
        ]
      });
      trialsReviewBarDates[`${startDate}_${endDate}`] = true;
      // negativeAxisNCTIds.push(`${index % 10}`); // TESTING: Need this for future reference
    }
  );

  // Rearrange the order of FDA approval bars with start date to avoid collision on the UI
  trialReviewBars = trialReviewBars.sort(
    (a: any, b: any) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
  );

  trialReviewBars.forEach((a: any, index: number) => {
    // eslint-disable-next-line no-param-reassign
    a.y = [index % 10];
    negativeAxisNCTIds.push(`${index % 10}`);
  });

  trialsReview = overlays[source]?.trials_review;

  overlays[source]?.trials_review.forEach(
    ({ id: nctId, fitted_start_date: startDate, submissionstatusdate: endDate }: any) => {
      const submissions = shapes.filter(
        shp => shp.source === 'submission' && isDateInRange(shp.endDate, startDate, endDate)
      );
      shapes.push({
        ...cloneDeep(horizontalBlackShapeDefaultConfig),
        x0: startDate,
        x1: endDate,
        y0: nctId,
        y1: nctId,
        source: 'trials_review',
        intersectingSubmissions: submissions.map(sub => sub.submission)
      });

      nctIdsInReview.push(nctId);
    }
  );
  const today = new Date();
  const date = today.toISOString().split('T')[0];
  shapes.push({
    ...todayVerticalLineDefaultConfig,
    line: {
      ...todayVerticalLineDefaultConfig.line,
      color: 'red'
    },
    x0: date,
    x1: date
  });
  shapes.push({
    ...todayVerticalLineDefaultConfig,
    line: {
      ...todayVerticalLineDefaultConfig.line,
      color: 'red'
    },
    yref: 'y2 domain',
    x0: date,
    x1: date
  });

  const annotationText = new Date().toLocaleDateString();
  annotations.push({
    ...todayAnnotationsDefaultConfig,
    x: date,
    text: annotationText
  });

  trialsInReview = overlays[source].trials_in_review;

  // Process Regulatory Information Meetings
  const { shapes: regInfoShapes, trialReviewBars: regInfoTrialReviewBars } =
    processRegulatoryInfoMeetings(meetingData);
  shapes.push(...regInfoShapes);
  trialReviewBars.push(...regInfoTrialReviewBars);
  return {
    shapes,
    annotations,
    trialsInReview,
    trialReviewBars,
    negativeAxisNCTIds,
    nctIdsInReview,
    trialsReview
  };
};

const getFieldValues = (data: { [key: string]: any }, field: string, fieldType: string) => {
  const keys = field.split('.');
  if (keys.length > 2) {
    // eslint-disable-next-line no-console
    console.error('keys depth more than 2 not allowed');
    return [];
  }

  const optionValue = data[keys[0]];
  if (!optionValue) {
    return [];
  }

  let values = [];
  if (fieldType === 'Array') {
    values = optionValue.map((val: any) => {
      if (val[keys[1]]) {
        return val[keys[1]];
      }

      return val;
    });
  } else if (fieldType === 'Number' || fieldType === 'String') {
    values = [optionValue];
  } else if (fieldType === 'Object') {
    values = optionValue[keys[1]];
  }

  return values;
};

export const getFilters = (
  apiData: any,
  type: 'FDA' | 'TRIALS',
  defaultFilters: { [key: string]: any } = {},
  applicationSource: string = 'us'
) => {
  console.log("getting Filters")
  const filters =  cloneDeep(
    (type === 'TRIALS'
      ? applicationSource === 'us'
        ? TRIALS_FILTERS
        : EU_TRIALS_FILTERS
      : applicationSource === 'us'
      ? FDA_FILTERS
      : EMA_FILTERS
    ).map((fltr:any) => ({
      ...fltr,
      options: [...(fltr?.options || [])]
    }))
  );
  console.log("len",apiData.length)

  apiData.forEach((trial: any) => {
    filters.forEach((filter: { [key: string]: any }) => {
      if (filter.filterType === 'radio_group') {
        return;
      }
      if (filter.filterType === 'year_range' && defaultFilters[filter.field]) {
        // eslint-disable-next-line no-param-reassign
        filter.value = {
          startDate: getDate(defaultFilters[filter.field].gte),
          endDate: getDate(defaultFilters[filter.field].lte)
        };
      }
      // eslint-disable-next-line no-param-reassign
      filter.options = sortBy(
        uniqBy(
          [
            ...(filter.options || []),
            ...getFieldValues(trial, filter.field, filter.fieldType).map(
              (val: string | number) => ({
                value: val,
                label: val,
                selected: defaultFilters[filter.field] === val
              })
            )
          ],
          'value'
        ),
        'label'
      ) as Array<any>;
    });
  });

  return filters;
};

export const mergeOldFilterValues = (newFilters: any, oldFilters: any) => {
  newFilters.forEach((newFltr: any) => {
    const oldFltr = oldFilters.find((fltr: any) => fltr.id === newFltr.id);
    if (newFltr.filterType === 'checkbox') {
      // eslint-disable-next-line no-param-reassign
      newFltr.options = newFltr.options.map((newOpt: any) => {
        const selected =
          oldFltr.options.findIndex(
            (oldOpt: any) => oldOpt.value === newOpt.value && oldOpt.selected
          ) > -1;

        return {
          ...newOpt,
          selected
        };
      });
      // In some of the cases where we use synonyms, we might not the options which actually was selected by user.
      // Example: ASD matches "Autism Spectrum Disorder". ASD might not be present in the result only "Autism Spectrum Disorder" could be there.
      // So to show ASD which was selected by user we need to append previously selected option if not present in the new filter options.
      // Example query is: "(active_ingredients:cannabidiol) AND (indications_and_usage:ASD) AND (sponsor_name:Assaf-Harofeh Medical Center OR sponsor_name:Canndoc Ltd OR sponsor_name:Canopy Growth Corporation)"
      // Above query gives only the indications_and_usage "Autism Spectrum Disorder" even though we specified "ASD"
      // To make "ASD" appear in the suggestion (since it was selected by user), we are running below logic
      const prevSelectedOptions = oldFltr?.options?.filter((oldOpt: any) => oldOpt?.selected);
      const prevOptionsMissingInNewOptions = prevSelectedOptions?.filter(
        (oldOpt: any) =>
          newFltr?.options?.findIndex((newOpt: any) => newOpt.value === oldOpt.value) === -1
      );
      // eslint-disable-next-line no-param-reassign
      newFltr.options = [...newFltr?.options, ...prevOptionsMissingInNewOptions];
    } else if (newFltr?.filterType === 'year_range' || newFltr?.filterType === 'radio_group') {
      if (oldFltr?.value) {
        // eslint-disable-next-line no-param-reassign
        newFltr.value = oldFltr?.value;
      }
    }
  });

  return newFilters;
};

export const mapGraphEntityData = (data: { [key: string]: string | number } | null) => {
  const mappedData: { [key: string]: string | number } = {};
  if (data) {
    Object.keys(data)
      .filter((key: string) => !!GRAPH_ENTITY_DATA_MAPPING[key])
      .forEach((key: string) => {
        mappedData[GRAPH_ENTITY_DATA_MAPPING[key]?.name || key] =
          GRAPH_ENTITY_DATA_MAPPING[key]?.getValue?.(data[key]) || data[key];
      });
  }

  return mappedData;
};

export const getTrialsGraphData = (
  responseData: { [key: string]: any },
  showOnlySBAsTrials: boolean
) => {
  if (showOnlySBAsTrials) {
    return {
      ...(responseData || {}),
      barGraphData: (responseData.barGraphData || []).filter(
        (data: { [key: string]: string }) =>
          responseData.trialsInReview?.includes(data.name as string) || data.yaxis === 'y2'
      ),
      nctIds: responseData.trialsInReview || []
    };
  }
  return {
    ...(responseData || {}),
    barGraphData: [...(responseData?.barGraphData || [])],
    nctIds: [...(responseData?.ctIds || [])]
  };
};

export const getGroupedFilter = (filters: any, query: any) => {
  const dateRange: any = {};
  filters.forEach((filter: any) => {
    if (filter.filterType === 'year_range') {
      if (filter.value?.startDate && filter.value?.endDate && !dateRange[filter.id]) {
        dateRange[filter.id] = {};
      }

      if (filter.value?.startDate) {
        dateRange[filter.id].gte = `${filter.value?.startDate.getFullYear()}-01-01`;
      }
      if (filter.value?.endDate) {
        dateRange[filter.id].lte = `${filter.value?.endDate.getFullYear()}-12-31`;
      }
    } else {
      const selectedOptions = filter.options.filter((opt: any) => opt.selected);
      // eslint-disable-next-line no-param-reassign
      query = [
        ...query,
        ...selectedOptions.map((opt: any) => ({ category: filter.id, searchText: opt.value }))
      ];
    }
  });
  return { dateRange, query };
};

export const geFDAFiltersPayload = (filters: any) => {
  const groupedFilters: any = {};
  filters.forEach((filter: any) => {
    if (filter.filterType === 'radio_group') {
      if (filter.value !== 'all') {
        groupedFilters[filter.id] = filter.value;
      }
    } else if (filter.filterType === 'checkbox') {
      const selectedOptions = filter.options.filter((opt: any) => opt.selected);
      const selectedValues = selectedOptions.map((opt: any) => opt.value);
      if (selectedValues.length > 0) {
        groupedFilters[filter.id] = selectedValues;
      }
    } else if (
      filter.filterType === 'year_range' &&
      filter.value?.startDate &&
      filter.value?.endDate
    ) {
      groupedFilters[filter.id] = [
        `${filter.value?.startDate.getFullYear()}-01-01`,
        `${filter.value?.endDate.getFullYear()}-12-31`
      ];
    }
  });

  return groupedFilters;
};

export const getDisplayableFilterValues = (filters: Array<any>) => {
  const selectedFilters: any = {};
  filters?.forEach((element: any) => {
    if (element.filterType === 'checkbox') {
      const selectedOptions = element.options
        .filter((opt: any) => opt.selected)
        .map((opt: any) => opt.label);

      if (selectedOptions.length > 0) {
        selectedFilters[element.label] = selectedOptions;
      }
    } else if (element.value && element.value !== 'all') {
      selectedFilters[element.label] = element.value;
    }
  });

  return selectedFilters;
};

export const addLabels = (bData: any, selectedLabels: any) => {
  bData
    .filter((bar: any) => bar.customType === 'fake_label')
    .forEach((data: any) => {
      const text = selectedLabels.reduce((acc: string, current: any) => {
        const label = current.prefix ? `${current.prefix}:` : '';
        return `${acc}${acc ? ' | ' : ''}${label} ${data.customdata[0][current.customDataIndex]}`;
      }, '');
      // eslint-disable-next-line no-param-reassign
      data.text = `${text}  `;
    });
};

// eslint-disable-next-line no-unused-vars
export const getSubmissionFlagOptions = (submissionFlags: any, prevSelectedOptions: any) => {
  return (
    submissionFlags?.map((flag: any) => ({
      endDate: flag.end_date,
      startDate: flag.start_date,
      submission: flag.submission,
      group: flag.title,
      selected: false,
      class: flag.submission_class_type,
      name: `${flag.submission}: ${flag.end_date}`
    })) || []
  );
};

export const getSubmissionAnnotationText = (submission: string) => {
  try {
    const trimmedText = submission.trim();
    const submissionNumber = (submission?.split('-')?.[1] || '').trim();
    return `${trimmedText[0]}-${submissionNumber}`;
  } catch (e) {
    return submission;
  }
};

export const getAllMeetings = (meetingsData: any) => {
  const meetingsList: any[] = [];

  Object.keys(meetingsData).forEach(year => {
    Object.keys(meetingsData[year]).forEach(month => {
      meetingsData[year][month].forEach((meeting: any) => {
        meetingsList.push(meeting);
      });
    });
  });

  return meetingsList;
};
